<!--批发退货入库-->
<template>
    <div class="RefundIn">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="销售机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据状态">
                            <el-select placeholder="请选择" v-model="form.stockStatus">
                                <el-option label="请选择" value="" />
                                <el-option label="待入库" value="0" />
                                <el-option label="已入库" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.sale.refundIn.open')">
                查询
            </el-button>
            <el-button type="primary" @click="handleIn" size="small" v-if="hasPrivilege('menu.sale.refundIn.stock')">
                入库
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.sale.refundIn.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left">
                    <!--                    type='selection'复选框 -->
                </el-table-column>
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="批发单号" width="180" prop="code" v-if="showColumn('code')" />
                <el-table-column label="销售机构" width="140" prop="repoName" v-if="showColumn('repoName')" />
                <el-table-column label="进货机构" width="140" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="单据金额"
                    width="100"
                    prop="bizMoney"
                    v-if="showColumn('bizMoney')"
                    key="bizMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="数量" width="100" prop="count" v-if="showColumn('count')" />
                <el-table-column
                    label="单据状态"
                    width="100"
                    prop="stockStatus"
                    v-if="showColumn('stockStatus')"
                    key="stockStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库时间"
                    width="140"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="85"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.sale.refundIn.open')"
                            >查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'RefundIn',
    mixins: [CheckTableShowColumn],
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                code: '',
                stockStatus: '',
                deptCode: '',
                search: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            depts: [],
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/trade/wholesale/refundIn/page',
                in: '/trade/wholesale/refundIn/in',
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.REPOSITORY).then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        stockStatus(stockStatus) {
            if (typeof stockStatus == 'undefined') {
                return '';
            }
            if (stockStatus == '0') {
                return '待入库';
            }
            if (stockStatus == '1') {
                return '已入库';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.sale.refundIn.detail', ['销售管理', '批发退货入库', '退货入库详情'], {
                form: row,
                code: row.code,
            });
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '批发退货入库', '/trade/wholesale/refundIn/export', this.form, codes);
        },

        handleIn() {
            if (this.$refs['table'].selection.length != 1) {
                this.$message.error('选择单据数量必须为一条');
            } else if (this.$refs['table'].selection[0].stockStatus != 0) {
                this.$message.error('商品仅能入库一次');
            } else {
                this.open();
            }
        },
        open() {
            this.$confirm('确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.in();
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消入库',
                    });
                });
        },
        in() {
            let _params = {};
            if (this.$refs['table'].selection[0]) {
                _params = {
                    codes: this.$refs['table'].selection[0].code,
                };
            } else {
                _params = {
                    codes: this.codes,
                };
            }
            UrlUtils.PostRemote(this, this.url.in, _params, null, () => {
                this.$confirm('入库成功');
                this.handleQuery();
            });
        },
    },
};
</script>

<style scoped>
.RefundIn .el-form-item {
    margin-bottom: 0;
}
</style>
